import { Box, Button, Typography } from '@mui/material'
import { CSButton } from 'Components/game/CSButton'
import { GameName } from 'Components/game/gameName'
import { GameProfile } from 'Components/game/gameProfile'
import { PickDropButton } from 'Components/game/pickDropButton'
import { HBox, VBox } from 'Components/layout'
import { ChildSpacerH, ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { SubtleText } from 'Components/visual/SubtleText'
import { CSTooltip } from 'Components/visual/csTooltip'
import { DarkList } from 'Components/visual/darkList'
import { PredictionBadgeMini } from 'Components/visual/scoreBadge/predictionBadgeMini'
import { ScoreBadge } from 'Components/visual/scoreBadge/scoreBadge'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGames } from 'store/game/gameContext'
import { useRounds } from 'store/game/roundContext'
import { usePickGame } from 'store/game/userPickGameContext'
import { useAuth } from 'store/user/authContext'
import { formatISODate } from 'util/date'
import {
  findLowestScoredGame,
  getGamePredictionScore,
  isDropAfterReleaseAllowed,
  isDropAllowed,
  isGameReleased,
  isGameSelected,
} from 'util/gameLogic'
import { sortByProperty } from 'util/sort'
import { BadgeCounters } from './badgeCounters'
import { PicksSummary } from './picksSummary'
import { ReleaseDate } from './releaseDate'
import { addDays } from 'date-fns'

export const YourGamesList = () => {
  const { user } = useAuth()
  const navigate = useNavigate()

  const { isLoadingUserGames, selectedGames, selectedGamesOutsideRound } =
    useGames()

  const { pickedGameList, gamesOutsideList, gamesNotFoundList } = usePickGame()

  const { mainRounds } = useRounds()

  const notFoundList = useMemo(() => {
    return gamesNotFoundList.map((game) => {
      return {
        id: game,
        name: 'Unknown ' + game,
        metacritic: null,
        selected: true,
        dropAllowed: true,
        dropAfterReleaseAllowed: true,
        missingData: true,
        released: formatISODate(addDays(mainRounds.current.startDate, 1)),
      }
    })
  }, [gamesNotFoundList, mainRounds])

  const sortedGames = useMemo(() => {
    let sortedGames =
      pickedGameList
        ?.sort((a, b) => sortByProperty(a, b, 'released', 'ASC'))
        .map((game) => {
          return {
            ...game,
            selected: isGameSelected(
              game,
              selectedGames,
              selectedGamesOutsideRound
            ),
            dropAllowed: isDropAllowed(game),
            dropAfterReleaseAllowed: isDropAfterReleaseAllowed(game),
            userPrediction: getGamePredictionScore(user, game),
          }
        }) ?? []

    const { scoredGamesCount, lowestScoreGameIndex } =
      findLowestScoredGame(sortedGames)

    if (scoredGamesCount >= 10 && lowestScoreGameIndex !== null) {
      sortedGames[lowestScoreGameIndex].lowest = true
    }

    return sortedGames
  }, [pickedGameList, user, selectedGames, selectedGamesOutsideRound])

  const sortedGamesOutsideRound = useMemo(() => {
    let sortedGamesOutsideRound =
      gamesOutsideList
        ?.filter((game) => {
          return game.released !== null
        })
        ?.sort((a, b) => sortByProperty(a, b, 'released', 'ASC'))
        .map((game) => {
          const gameDecorated = {
            ...game,
            selected: isGameSelected(
              game,
              selectedGames,
              selectedGamesOutsideRound
            ),
            selectedOverride: true,
            dropAllowed: isDropAllowed(game),
            dropAfterReleaseAllowed: isDropAfterReleaseAllowed(game),
          }
          return gameDecorated
        }) ?? []

    return sortedGamesOutsideRound
  }, [gamesOutsideList, selectedGames, selectedGamesOutsideRound])

  // Columns
  const columns = [
    {
      ColComponent: ({ colData }) => {
        function ContentComponent() {
          return (
            <>
              <Typography>
                <strong>Lowest scoring game</strong>
                <br />
                At the end of the round the lowest scoring game won't count
                towards your total. This includes a game not scoring at all.
              </Typography>
            </>
          )
        }

        // Show badge if game is unreleased or is released and the logged in user made a prediction
        let showPredictionBadge = false

        if (
          (user.isAuthenticated && colData.entry.userPrediction > 0) ||
          (!isGameReleased(colData.entry) && !colData.entry.metacritic)
        ) {
          showPredictionBadge = true
        }

        return (
          <VBox align="left" style={{ width: '100%' }}>
            <GameName game={colData.entry} />
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              {!colData.entry.missingData && (
                <>
                  <GameProfile game={colData.entry} />
                  <ReleaseDate
                    game={colData.entry}
                    enableFutureGameWarning={true}
                  />
                </>
              )}

              <HBox align="center-right">
                <ChildSpacerH>
                  {showPredictionBadge && (
                    <>
                      <PredictionBadgeMini
                        score={colData.entry.userPrediction}
                      />
                    </>
                  )}
                  {colData.entry.metacritic && (
                    <>
                      {colData.entry.lowest ? (
                        <CSTooltip
                          id={'score' + colData.entry.id}
                          text={<ContentComponent />}
                        >
                          <span>
                            <ScoreBadge
                              game={colData.entry}
                              lowestScored={colData.entry.lowest}
                              text="Score"
                            />
                          </span>
                        </CSTooltip>
                      ) : (
                        <span>
                          <ScoreBadge
                            game={colData.entry}
                            lowestScored={colData.entry.lowest}
                            text="Score"
                          />
                        </span>
                      )}
                    </>
                  )}
                  <Box p={3} />
                  {colData?.entry?.slug && (
                    <CSButton
                      onClick={() => {
                        navigate('/game/' + colData.entry.slug)
                      }}
                      label="View"
                    />
                  )}
                  <PickDropButton
                    game={colData.entry}
                    ButtonComponent={CSButton}
                  />
                </ChildSpacerH>
              </HBox>
            </div>
          </VBox>
        )
      },
    },
  ]

  return (
    <>
      <BadgeCounters gameList={pickedGameList} />
      <Box p={3} />
      <PicksSummary />
      <Box p={5} />
      <VBox>
        {notFoundList.length > 0 && (
          <>
            <h3>
              <HBox align="center-left" style={{ gap: '8px' }}>
                <span className="material-symbols-rounded">warning</span> Games
                missing data
              </HBox>
            </h3>
            <DarkList
              isLoading={isLoadingUserGames}
              stackOnMobile={false}
              hoverEffect="scaleFX"
              subLabel="These games lost their data and no longer counts. Drop them for free and pick a replacement."
              emptyText=""
              list={notFoundList}
              columns={columns}
            ></DarkList>
            <Box p={5} />
          </>
        )}

        {sortedGamesOutsideRound.length > 0 && (
          <>
            <h3>
              <HBox align="center-left" style={{ gap: '8px' }}>
                <span className="material-symbols-rounded">warning</span> Games
                that changed round
              </HBox>
            </h3>
            <DarkList
              isLoading={isLoadingUserGames}
              stackOnMobile={false}
              hoverEffect="scaleFX"
              subLabel="These games no longer counts. Drop them for free and pick a replacement."
              emptyText=""
              list={sortedGamesOutsideRound}
              columns={columns}
            ></DarkList>
            <Box p={5} />
          </>
        )}

        <h3>Your active picks</h3>
        <DarkList
          isLoading={isLoadingUserGames}
          stackOnMobile={false}
          hoverEffect="scaleFX"
          emptyText={
            <>
              <ChildSpacerVAll>
                <div>
                  Pick up to 10 games to your profile on the{' '}
                  <strong>Games</strong> page to play for a spot in the Picks
                  standings.
                </div>
              </ChildSpacerVAll>
              <Button
                variant="outlined"
                onClick={() => {
                  navigate('/games')
                }}
                style={{ width: '100%' }}
              >
                Go to Games
              </Button>
            </>
          }
          list={sortedGames}
          /* onRowClick={(rowData) => navigate('/game/' + rowData.slug)} */
          columns={columns}
        ></DarkList>
        <Box p={3} />
        <SubtleText>
          The lowest scoring game in a round will not be counted if you score 10
          games.
        </SubtleText>
      </VBox>
    </>
  )
}
