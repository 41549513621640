import { CSTooltip } from 'Components/visual/csTooltip'
import { isSameSecond } from 'date-fns'
import { useRounds } from 'store/game/roundContext'
import { primitiveColors } from 'styles/primitiveColors'
import { semanticMedia } from 'styles/semanticMedia'
import {
  buildTodayZero,
  formatRelativeDate,
  formatShortDate,
  isCurrentYear,
  isWithinWeekBefore,
} from 'util/date'

export const ReleaseDateFormatter = ({ releaseDate, absolute }) => {
  const { mainRounds, selectedRound } = useRounds()
  const currentRound = mainRounds.current

  if (!releaseDate) {
    return null
  }

  const isCurrentRound = selectedRound.id === currentRound.id

  let formattedReleaseDate = null

  const isCurrentYearValue = isCurrentYear(currentRound)

  const today = buildTodayZero()

  if (absolute) {
    if (!isCurrentYearValue) {
      formattedReleaseDate = releaseDate
    } else {
      formattedReleaseDate = formatShortDate(releaseDate)
    }
  } else {
    if (!isCurrentRound || !isWithinWeekBefore(releaseDate, today)) {
      formattedReleaseDate = formatShortDate(releaseDate)
    } else if (isCurrentRound && isWithinWeekBefore(releaseDate, today)) {
      formattedReleaseDate = formatRelativeDate(releaseDate)
    } else if (!isCurrentYearValue) {
      formattedReleaseDate = releaseDate
    }
  }

  const isDangerousDate =
    isSameSecond(releaseDate, selectedRound.endDate) ||
    isSameSecond(releaseDate, selectedRound.startDate)

  return (
    <span
      style={{
        fontSize: '14px',
        color: isDangerousDate
          ? primitiveColors.pColorRed500
          : semanticMedia.contrastLow,
      }}
    >
      <CSTooltip
        text="Games releasing the first or the last day of the round are usually placeholder and should be avoided."
        isActive={isDangerousDate}
      >
        {formattedReleaseDate}
      </CSTooltip>
    </span>
  )
}
