const states = {
  authToken: 'AuthToken',
  teamName: 'teamName',
  selectedGames: 'selectedGames',
  loginProvider: 'loginProvider',
  gameFilter: 'gameFilter',
  selectedRoundId: 'selectedRoundId',
}

function clearTeamName() {
  localStorage.removeItem(states.teamName)
}

function clearSelectedGames() {
  localStorage.removeItem(states.selectedGames)
}
export const browserState = {
  setLoginProvider: (providerName) => {
    localStorage.setItem(states.loginProvider, providerName)
  },
  getLoginProvider: () => {
    return localStorage.getItem(states.loginProvider)
  },
  clearLoginProvider: () => {
    localStorage.removeItem(states.loginProvider)
  },

  setAuthToken: (authToken) => {
    localStorage.setItem(states.authToken, authToken)
  },
  getAuthToken: () => {
    return localStorage.getItem(states.authToken)
  },
  clearAuthToken: () => {
    localStorage.removeItem(states.authToken)
  },

  setTeamName: (teamName) => {
    localStorage.setItem(states.teamName, teamName)
  },
  getTeamName: () => {
    return localStorage.getItem(states.teamName)
  },
  clearTeamName,

  saveSelectedGames: (selectedGames) => {
    localStorage.setItem(states.selectedGames, JSON.stringify(selectedGames))
  },
  getSelectedGames: () => {
    return JSON.parse(localStorage.getItem(states.selectedGames))
  },
  clearSelectedGames,

  clearSignupData: () => {
    clearTeamName()
    clearSelectedGames()
  },

  saveGameFilter: (gameFilter) => {
    localStorage.setItem(states.gameFilter, JSON.stringify(gameFilter))
  },

  getGameFilter: () => {
    try {
      const gameFilter = localStorage.getItem(states.gameFilter)
      const parsedGameFilter = JSON.parse(gameFilter)
      return parsedGameFilter
    } catch (e) {
      console.error(e)
      return null
    }
  },

  setSelectedRoundId: (roundId) => {
    localStorage.setItem(states.selectedRoundId, roundId)
  },
  getSelectedRoundId: () => {
    return localStorage.getItem(states.selectedRoundId)
  },
}
