import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { ContactMobile } from './contactMobile'

export const MobileFooter = () => {
  return (
    <ChildSpacerVAll>
      <ContactMobile />
    </ChildSpacerVAll>
  )
}
