import { Tooltip, Zoom, tooltipClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: '16px',
  },
}))

export const CSTooltip = ({ text, children, isActive = true }) => {
  if (!isActive) {
    return children
  }

  return (
    <LightTooltip TransitionComponent={Zoom} title={text}>
      {children}
    </LightTooltip>
  )
}
