export const spacing = {
  xs: '8px',
  sm: '12px',
  md: '16px',
  lg: '24px',
  xl: '32px',
  xxl: '48px',
  xxxl: '64px',
  pageMobile: '16px',
  pageTablet: '48px',
  pageDesktop: '96px',
}
