import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { SubtleText } from 'Components/visual/SubtleText'

export const LeaderboardsUpdatedText = () => {
  const leaderboardsUpdateText = `Games and reviews are updated 3 times a day.`

  return (
    <ChildSpacerV>
      <SubtleText align="center" text={leaderboardsUpdateText} />
    </ChildSpacerV>
  )
}
