import { VBox } from 'Components/layout'
import { Breakpoint } from 'react-socks'
import { query } from 'styles/mediaQueries'
import { spacing } from 'styles/spacing'
import { colors } from './../../styles/colors'
import { Contact } from './contact'
import { MobileFooter } from './mobileFooter'

export const Footer = () => {
  return (
    <>
      <Breakpoint customQuery={query.mobile}>
        <VBox
          align={'center'}
          style={{
            backgroundColor: colors.darkPurple,
            padding: `${spacing.xl} ${spacing.xl} ${spacing.xxxl} ${spacing.xl}`,
          }}
        >
          <MobileFooter />
        </VBox>
      </Breakpoint>
      <Breakpoint customQuery={query.mobileUp}>
        <VBox
          align={'center'}
          style={{
            backgroundColor: colors.darkPurple,
            padding: `${spacing.pageTablet} ${spacing.pageTablet} ${spacing.xxxl} ${spacing.pageTablet}`,
          }}
        >
          <Contact />
        </VBox>
      </Breakpoint>
    </>
  )
}
