import { SubtleText } from 'Components/visual/SubtleText'
import { colors } from 'styles/colors'
import { ExternalLink } from '../interactive/ExternalLink'
import { LeaderboardsUpdatedText } from './leaderboardsUpdatedText'
import { VBox } from 'Components/layout'
import { spacing } from 'styles/spacing'

export const ContactMobile = () => {
  return (
    <>
      <VBox align={'center'} style={{ gap: spacing.lg }}>
        <div>
          <span
            className="material-symbols-rounded"
            style={{ fontSize: 18, marginRight: 8, color: colors.theme }}
          >
            forum
          </span>
          <ExternalLink
            url="https://discord.gg/KrrCYAx9vZ"
            label="Critical Showdown Discord"
          />
        </div>
        <div>
          <span
            className="material-symbols-rounded"
            style={{ fontSize: 18, marginRight: 8, color: colors.theme }}
          >
            chat
          </span>
          <ExternalLink
            url="https://x.com/critshowdown"
            label="@CritShowdown X/Twitter"
          />
        </div>
        <div>
          <span
            className="material-symbols-rounded"
            style={{ fontSize: 18, marginRight: 8, color: colors.theme }}
          >
            shopping_bag
          </span>
          <ExternalLink
            url="https://critical-showdown.myspreadshop.se"
            label="Critical Showdown Merch"
          />
        </div>

        <ExternalLink
          url="https://igdb.com"
          label="Games metadata powered by IGDB.com"
        />
        <SubtleText
          align={'center'}
          text="Currently only Xbox One/S/X, PS4/5 and Nintendo Switch games are included in the game."
        ></SubtleText>
        <LeaderboardsUpdatedText />
      </VBox>
    </>
  )
}
