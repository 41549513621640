import { Typography } from '@mui/material'
import { semanticMedia } from 'styles/semanticMedia'

export const SubtleText = ({ text, align, children, nowrap }) => {
  return (
    <Typography
      style={{
        color: semanticMedia.contrastLow,
        fontSize: '12px',
        whiteSpace: nowrap ? 'nowrap' : 'normal',
        textAlign: align ? align : 'left',
      }}
    >
      {text || children}
    </Typography>
  )
}
