import { VBox } from 'Components/layout'
import { AdminPage } from 'pages/admin/admin'
import { GamesContent } from 'pages/home/games/gamesContent'
import { HomeContent } from 'pages/home/home/homeContent'
import { HowToPlayContent } from 'pages/home/howToPlay/howToPlayContent'
import { LeaderboardsContent } from 'pages/home/leaderboards/leaderboardsContent'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { BreakpointProvider, setDefaultBreakpoints } from 'react-socks'
import { LeaderboardsProvider } from 'store/leaderboard/leaderboardContext'
import { HomePage } from './pages/home/home'
import './styles/App.css'

import { GameView } from 'pages/home/games/gameView'
import { TeamPredictionsView } from 'pages/home/leaderboards/teamPredictionsView'
import { TeamView } from 'pages/home/leaderboards/teamView'
import { NoMatch } from 'pages/home/noMatch'
import { ProfilePanelView } from 'pages/home/profilePanel/profilePanelView'
import { SignInView } from 'pages/home/profilePanel/signInView'
import { SignupView } from 'pages/home/profilePanel/signUpView'
import { SelectRoundPage } from 'pages/home/selectRound'
import { SignInViewContextProvider } from 'store/signInViewContext'
import { SignUpViewContextProvider } from 'store/signupViewContext'
import { colors } from 'styles/colors'

setDefaultBreakpoints([
  { xs: 0 },
  { s: 850 },
  { m: 1210 },
  { l: 1350 },
  { xl: 1900 },
  { xxl: 2500 },
])

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <HomePage />,
      children: [
        {
          path: '/',
          element: <HomeContent />,
        },
        {
          path: '/sign-in',
          element: <SignInView />,
        },
        {
          path: '/sign-up',
          element: <SignupView />,
        },
        {
          path: '/user',
          element: <ProfilePanelView />,
        },
        {
          path: '/team/:teamUserId',
          element: <TeamView />,
        },
        {
          path: '/team-manage/:tab?',
          element: <ProfilePanelView />,
        },
        {
          path: '/teamPredictions/:teamUserId',
          element: <TeamPredictionsView />,
        },
        {
          path: '/game/:slug',
          element: <GameView />,
        },
        {
          path: '/games',
          element: <GamesContent />,
        },
        {
          path: '/leaderboards/:selectedBoard?',
          element: (
            <LeaderboardsProvider>
              <LeaderboardsContent />
            </LeaderboardsProvider>
          ),
        },
        {
          path: '/select-round',
          element: <SelectRoundPage />,
        },
        {
          path: '/how-to-play',
          element: <HowToPlayContent />,
        },
        {
          path: '*',
          element: <NoMatch />,
        },
      ],
    },
    {
      path: '/admin',
      element: <AdminPage />,
    },
    {
      path: '*',
      element: <NoMatch />,
    },
  ])

  return (
    <VBox>
      <BreakpointProvider>
        <div style={{ backgroundColor: colors.appBackground, height: '100%' }}>
          <SignUpViewContextProvider>
            <SignInViewContextProvider>
              <LeaderboardsProvider>
                <RouterProvider router={router} />
              </LeaderboardsProvider>
            </SignInViewContextProvider>
          </SignUpViewContextProvider>
        </div>
      </BreakpointProvider>
    </VBox>
  )
}
export default App
