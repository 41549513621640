import { CssBaseline } from '@mui/material'
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles'
import React from 'react'
import ReactDOM from 'react-dom/client'

import '@fontsource/work-sans'
import { UINotifSnackbar } from 'Components/visual/uiNotifSnackbar'
import { ErrorBoundary } from 'react-error-boundary'
import { FriendsProvider } from 'store/game/friendsContext'
import { GamesProvider } from 'store/game/gameContext'
import { GameFilterContextProvider } from 'store/game/gameFilterContext'
import { PredictionsProvider } from 'store/game/predictionsContext'
import { RoundsProvider } from 'store/game/roundContext'
import { UINotifsProvider } from 'store/game/uiNotificationsContext'
import { PickGameProvider } from 'store/game/userPickGameContext'
import { PredictGameProvider } from 'store/game/userPredictGameContext'
import { WatchlistProvider } from 'store/game/watchlistContext'
import { AuthContextProvider } from 'store/user/authContext'
import { CacheContextProvider } from 'util/cacheContext'
import {
  buildCurrentRound,
  buildFutureRound,
  buildPreviousRound,
} from 'util/round'
import App from './App'
import { colors } from './styles/colors'

let theme = createTheme({
  spacing: [0, 2, 4, 8, 16, 24, 32, 64, 96, 128, 256, 512],
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          height: '40px',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: 'none',
          backgroundColor: colors.appBackground,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          maxWidth: '500px',
          width: '80%',
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: colors.theme,
    },
    /*     secondary: {
      main: colors.mint,
    },
 */
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    fontFamily: ['Work Sans', 'Arial', 'sans-serif'].join(','),
  },
})

theme = responsiveFontSizes(theme)

const currentRound = buildCurrentRound()
const mainRounds = {
  current: currentRound,
  next: buildFutureRound(1),
  prev: buildPreviousRound(currentRound),
}

const logError = (error, info) => {
  // Do something with the error, e.g. log to an external API
  console.error(error, info)
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ErrorBoundary
      fallback={
        <div style={{ padding: '32px' }}>
          <h2>Critical showdown</h2>
          <p></p>
          <p>
            Something went wrong. Please consider letting us know what happened
            on our Discord, preferably with:
          </p>
          <ul>
            <li>any error message</li>
            <li>the page you were on and what you did before it happened</li>
            <li>the time</li>
            <li>
              any screenshots or any errors from your browser console you
              managed to capture.
            </li>
          </ul>
          <p></p>
          <p>Thanks for helping us make the site better!</p>
        </div>
      }
      onError={logError}
    >
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <CacheContextProvider>
            <RoundsProvider mainRounds={mainRounds}>
              <PredictionsProvider>
                <AuthContextProvider>
                  <GamesProvider>
                    <GameFilterContextProvider>
                      <UINotifsProvider>
                        <PickGameProvider>
                          <WatchlistProvider>
                            <PredictGameProvider>
                              <FriendsProvider>
                                <UINotifSnackbar />
                                <App />
                              </FriendsProvider>
                            </PredictGameProvider>
                          </WatchlistProvider>
                        </PickGameProvider>
                      </UINotifsProvider>
                    </GameFilterContextProvider>
                  </GamesProvider>
                </AuthContextProvider>
              </PredictionsProvider>
            </RoundsProvider>
          </CacheContextProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </ErrorBoundary>
  </React.StrictMode>
)
